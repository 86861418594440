<!-- 支付成功页面 -->
<template>
  <HeadGoBackVue iCustomizdde="true" v-if="show == true">
    <template #default>
      <span> {{ $fanyi("充值") }} </span>
    </template>
  </HeadGoBackVue>
  <HeadGoBackVue iCustomizdde="true" v-else>
    <template #default>
      <span> {{ $fanyi("支付方式选择") }} </span>
    </template>
  </HeadGoBackVue>

  <div>
    <!--? 充值成功页面 -->
    <div class="paymentSuccessBox" v-if="show == true">
      <img
        class="successIcon"
        :src="require('@/assets/icon/chenggong.png')"
        alt=""
      />
      <p
        class="successFont"
        v-if="['2', '3', '4'].includes($route.query.userSelectedPaymentMethod)"
      >
      <p> 入金報告操作成功</p> 
      <p>※ご入金がまだ完了しておりません。お客様がご指定した銀行に入カされた金額と口座名義でお振込みをお願いいたします。</p>
      <p>※入金反映には少々お時間が掛かります。何度も同じ操作を繰り返されますと、入金確認待ち金額が累積されますので、実際の入金額と一致せず、調査に時間が掛かってしまいますので、１回のみでお願いします。</p>



      </p>

      <div class="transactionNumber">
        <p>
          <span class="title"> {{ $fanyi("入金金额") }}: </span>
          <span class="con">
            <span style="color: #b4272b; font-size: 28px">{{  $fun.JPYNumSegmentation(Number(price||0)) }} </span>
            {{ "円" }}</span
          >
        </p>
        <p>
          <span class="title"> {{ $fanyi("交易流水号") }}: </span>
          <span class="con">{{ runningnumber }}</span>
        </p>
      </div>

      <button
        class="operationButton red"
        style="width: 100%"
        @click="$fun.routerToPage('/user/purse')"
      >
        {{ $fanyi("回到钱包") }}
      </button>
      <!-- <button class="operationButton red" @click="$fun.routerToPage('/user/index')">
        {{ $fanyi("回到个人中心") }}
      </button>
      <button class="operationButton" @click="$fun.routerToPage('/')">
        {{ $fanyi("回到首页") }}
                                                                          </button> -->
    </div>
    <!-- !订单支付页面 -->

    <div class="paymentSuccessBox" v-if="show == false">
      <img
        class="successIcon"
        :src="require('@/assets/icon/chenggong.png')"
        alt=""
      />
      <h2>{{ $fanyi("支付成功") }}</h2>
      <div class="price">
        {{ $fun.JPYNumSegmentation(Number(price||0)) }} <span>{{ $fanyi("円") }}</span>
      </div>
      <div class="transactionNumber">
        <p>
          <span class="title"> {{ $fanyi("订单号") }}: </span>
          <span class="con">
            {{ order_sn }}
          </span>
        </p>
        <p>
          <span class="title"> {{ $fanyi("交易流水号") }}: </span>
          <span class="con">{{ runningnumber }}</span>
        </p>
      </div>

      <button
        class="operationButton red"
        style="width: 100%"
        @click="userOperation('backusercenter')"
      >
        {{ $fanyi("回到个人中心") }}
      </button>
      <button
        class="operationButton"
        style="width: 100%"
        @click="userOperation('backhome')"
      >
        {{ $fanyi("回到首页") }}
      </button>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
import HeadGoBackVue from "@/components/headGoBack";
const { proxy } = getCurrentInstance();
import { useRoute } from "vue-router";
const show = ref(false);
const route = useRoute();
// 支付价格
const price = ref(0);
// 订单号
const order_sn = ref("");
// 流水号
const runningnumber = ref("");
// 定义解析数据函数 //解码base64【解决中文乱码问题】
// const base64Decode = (str) => {
//   return decodeURIComponent(atob(str).split('').map(function (c) {
//     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//   }).join(''));
// }
// 用户点击按钮操作
const userOperation = (type) => {
  let appMethods = {
    backusercenter: () => {
      try {
        android.backPerson();
      } catch (e) {
        try {
          window.webkit.messageHandlers.goPerson.postMessage("");
        } catch (e) {
          proxy.$fun.routerToPage("/user/index");
        }
      }
    },
    backhome: () => {
      try {
        android.backHome();
      } catch (e) {
        try {
          window.webkit.messageHandlers.goHome.postMessage("");
        } catch (e) {
          proxy.$fun.routerToPage("/");
        }
      }
    },
  };
  appMethods[type]();
  // let isAndroid = null;
  // // let ran = navigator.userAgent;
  // // let isAndroid = ran.indexOf("Android") > -1 || ran.indexOf("Linux") > -1;
  // // let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  // // alert(isAndroid, 5, isIOS);
  // switch (type) {
  //   case "backusercenter":
  //     if (isAndroid) {
  //       // 安卓代码块
  //       appMethods[type]();
  //     } else {
  //       proxy.$fun.routerToPage("/user/index");
  //     }
  //     break;

  //   case "backhome":
  //     if (isAndroid) {
  //       // 安卓代码块
  //       appMethods[type]();
  //     } else {
  //       proxy.$fun.routerToPage("/");
  //     }
  //     break;
  // }
};

if (route.query.serial_number) {
  console.log(route.query.serial_number);
  proxy.$api
    .payPalSuccess({
      serial_number: route.query.serial_number,
    })
    .then((res) => {
      if (res.data.type_name == "充值余额") {
        show.value = true;
      } else {
        show.value = false;
      }
      price.value = res.data.pay_amount;
      order_sn.value = res.data.from_sn;
      runningnumber.value = res.data.serial_number;
      console.log(res);
    });
}

// //执行解码
// document.getElementById('submit').onclick = function () {
//   let str = document.getElementById('code').value;//get接收到的参数data
//   str = decodeURIComponent(str);//url转码
//   str = base64Decode(str);//base64转json字符串

//   let obj = JSON.parse(str);//json字符串转对象

//   console.log(obj);
// }

//------------------------ data -----------------------------------------
//------------------------ pageLoad -------------------------------------
//------------------------ methods --------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.paymentSuccessBox {
  width: 690px;
  background: #ffffff;
  border-radius: 6px;
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto 0;

  .successIcon {
    width: 80px;
    height: 80px;
    margin-bottom: 41px;
  }

  .successFont {
    font-size: 32px;
    color: #000000;
    line-height: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    p{
      font-size: 27px;
      line-height: 35px;
      text-align: left;
      &:first-child{
        font-size: 32px;
        margin-bottom: 15px;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  h2 {
    font-size: 32px;

    font-weight: 600;
    color: #000000;
  }

  .price {
    font-size: 56px;

    font-weight: 600;
    color: #b4272b;
    margin-top: 30px;

    span {
      font-size: 24px;

      font-weight: 400;
      color: #b4272b;
    }
  }

  .transactionNumber {
    margin-top: 60px;
    padding: 42px 0px;
    width: 100%;

    p {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 42px;

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        font-size: 28px;
        color: #000000;

        text-align: left;
      }

      .con {
        font-size: 28px;
        color: #000000;
      }
    }
  }

  .operationButton {
    padding: 0 50px;
    height: 70px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    font-size: 28px;
    margin-top: 20px;
    color: #000000;
  }

  .operationButton.red {
    margin-top: 30px;
    background: #b4272b;
    color: #ffffff;
  }
}
</style>
